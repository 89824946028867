<i18n locale="th" lang="yaml" src="@i18n/customer/customer.th.yaml"></i18n>

<template>
	<a-card
		:loading="loading"
		:hoverable="hoverable"
		:bordered="bordered"
		:class="cardCssClasses">
		<div class="mycard-content">
			<ul class="mycard-details">
				<li>
					<span class="title text-bold">{{customer.name}}</span>
					<span v-if="$notEmpty(customer.nick_name)">{{customer.nick_name}}</span>
				</li>
				<li v-if="$notEmpty(customer.identity_no)">
					<span>{{$t('customer.field.identity_no')}} : {{customer.identity_no}}</span>
				</li>
				<li v-if="$notEmpty(customer.phone)">
					<PhoneLink :value="customer.phone" />
				</li>
				<li v-if="$notEmpty(customer.email)">
					<EmailLink :value="customer.email"/>
				</li>
				<li class="customer-address">{{customerAddressDisplay}}</li>
				<li class="mycard-tags">
					<a-tag :class="tagCssClasses">{{$tenum('customer_type',customer.customer_type)}}</a-tag>
				</li>
			</ul>
			<div v-if="hasActionSlot" class="mycard-side-actions">
				<slot name="action"></slot>
			</div>
		</div>
	</a-card>
</template>

<script>
import {Tag} from "ant-design-vue"
import {addressDisplay} from "@utils/stringUtil"
import PhoneLink from "@components/common/PhoneLink.vue"
import EmailLink from "@components/common/EmailLink.vue"
export default {
	components : {
		"a-tag" : Tag,
		PhoneLink , EmailLink ,
	},
	props : {
		loading : {
			type: Boolean,
			default : false,
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		hoverable :{
			type : Boolean,
			default : false,
		} ,
		selected : {
			type : Boolean,
			default : false
		} ,
		disabled : {
			type : Boolean,
			default : false
		} ,
		customer : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		hasActionSlot() {
			return !!this.$slots.action && !this.disabled
		} ,
		customerAddressDisplay() {
			return addressDisplay(this,this.customer.address,this.customer);
 		} ,
		cardCssClasses() {
			return [
				"customer-card","mycard","mycard-columns","mycard-with-details",
				{
					"mycard-sm" : this.size == 'small' ,
					"mycard-selected" : this.selected ,
					"mycard-disabled" : this.disabled ,
				}
			]
		} ,
		tagCssClasses() {
			switch(this.customer.customer_type) {
				case "people" :
					return 'mytag-primary';
				case "juristic" :
					return "mytag-info";
				default :
					return "";
			}
		}
	}
}
</script>
<style lang="less" scoped>
	.customer-address {
		font-size : 0.9em;
	}
</style>
